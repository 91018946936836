import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import { SanityMarquee } from '@data/sanity/queries/types/modules'
import { Marqy } from '@lib/marqy'
import { serializers } from '@lib/serializers'

import Photo from '@components/photo'
import ProductCard from '@blocks/shop/product-card'

type MarqueeProps = SanityMarquee

const Marquee = ({
  content,
  contentAlignment,
  items,
  speed,
  maxItemWidth,
  reverse,
  pausable,
}: MarqueeProps) => {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <section>
      {content && (
        <div className="container">
          <BlockContent
            renderContainerOnSingleChild
            className={cx('rc rc-marquee flex flex-col', {
              'items-start text-left': contentAlignment === 'left',
              'items-center text-center': contentAlignment === 'center',
              'items-end text-right': contentAlignment === 'right',
            })}
            blocks={content}
            serializers={serializers}
          />
        </div>
      )}
      <Marqy
        speed={speed ?? 0.5}
        direction={reverse ? 'right' : 'left'}
        pauseOnHover={pausable}
      >
        <div className="flex">
          {items.map((item) => {
            switch (item._type) {
              case 'simple': {
                return (
                  <span
                    key={item._key}
                    className="block p-4 text-5xl leading-none"
                  >
                    {item.text}
                  </span>
                )
              }

              case 'photo': {
                return (
                  <div
                    key={item._key}
                    className="p-4 flex justify-center items-center"
                    style={{
                      width: 'calc(80vw - 2rem)',
                      maxWidth: maxItemWidth ?? '28rem',
                    }}
                  >
                    <Photo image={item.photo} hasPlaceholder={false} />
                  </div>
                )
              }

              case 'product': {
                return (
                  <div
                    key={item._key}
                    className="p-4 flex flex-col -my-px mx-0"
                    style={{
                      width: 'calc(80vw - 2rem)',
                      maxWidth: maxItemWidth ?? '28rem',
                    }}
                  >
                    <ProductCard
                      className="flex-1"
                      product={item.product}
                      hasVisuals
                      showThumbnails
                      showPrice
                      showQuickAdd
                    />
                  </div>
                )
              }
            }
          })}
        </div>
      </Marqy>
    </section>
  )
}

export default Marquee
